.App {
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scrachCard {
    width: 50%;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 2em;

    .title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1em;
    }

    .notice {
        margin-bottom: 1em;
    }
}

.resultsCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    .resultsCard {
        width: 30%;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 0.5em;
        padding: 1em;
        box-sizing: border-box;
    }
}